function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import Axios from 'axios';
import defu from 'defu';
import axiosRetry from 'axios-retry';

// Axios.prototype cannot be modified
var axiosExtra = {
  setBaseURL(baseURL) {
    this.defaults.baseURL = baseURL;
  },
  setHeader(name, value) {
    var scopes = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'common';
    for (var scope of Array.isArray(scopes) ? scopes : [scopes]) {
      if (!value) {
        delete this.defaults.headers[scope][name];
        continue;
      }
      this.defaults.headers[scope][name] = value;
    }
  },
  setToken(token, type) {
    var scopes = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'common';
    var value = !token ? null : (type ? type + ' ' : '') + token;
    this.setHeader('Authorization', value, scopes);
  },
  onRequest(fn) {
    this.interceptors.request.use(config => fn(config) || config);
  },
  onResponse(fn) {
    this.interceptors.response.use(response => fn(response) || response);
  },
  onRequestError(fn) {
    this.interceptors.request.use(undefined, error => fn(error) || Promise.reject(error));
  },
  onResponseError(fn) {
    this.interceptors.response.use(undefined, error => fn(error) || Promise.reject(error));
  },
  onError(fn) {
    this.onRequestError(fn);
    this.onResponseError(fn);
  },
  create(options) {
    return createAxiosInstance(defu(options, this.defaults));
  }
};

// Request helpers ($get, $post, ...)
var _loop = function _loop(method) {
  axiosExtra['$' + method] = function () {
    return this[method].apply(this, arguments).then(res => res && res.data);
  };
};
for (var method of ['request', 'delete', 'get', 'head', 'options', 'post', 'put', 'patch']) {
  _loop(method);
}
var extendAxiosInstance = axios => {
  for (var key in axiosExtra) {
    axios[key] = axiosExtra[key].bind(axios);
  }
};
var createAxiosInstance = axiosOptions => {
  // Create new axios instance
  var axios = Axios.create(axiosOptions);
  axios.CancelToken = Axios.CancelToken;
  axios.isCancel = Axios.isCancel;

  // Extend axios proto
  extendAxiosInstance(axios);

  // Intercept to apply default headers
  axios.onRequest(config => {
    config.headers = _objectSpread(_objectSpread({}, axios.defaults.headers.common), config.headers);
  });

  // Setup interceptors

  axiosRetry(axios, {
    "retries": 1,
    "retryDelay": function exponentialDelay() {
      var retryNumber = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
      var error = arguments.length > 1 ? arguments[1] : undefined;
      var delayFactor = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 100;
      var delay = Math.pow(2, retryNumber) * delayFactor;
      var randomSum = delay * 0.2 * Math.random(); // 0-20% of the delay

      return delay + randomSum;
    },
    "retryCondition": () => true
  });
  return axios;
};
export default ((ctx, inject) => {
  // runtimeConfig
  var runtimeConfig = ctx.$config && ctx.$config.axios || {};
  // baseURL
  var baseURL = process.browser ? runtimeConfig.browserBaseURL || runtimeConfig.browserBaseUrl || runtimeConfig.baseURL || runtimeConfig.baseUrl || '/' : runtimeConfig.baseURL || runtimeConfig.baseUrl || process.env._AXIOS_BASE_URL_ || 'http://varnish_plus:8100';

  // Create fresh objects for all default header scopes
  // Axios creates only one which is shared across SSR requests!
  // https://github.com/mzabriskie/axios/blob/master/lib/defaults.js
  var headers = {
    "common": {
      "Accept": "application/json, text/plain, */*",
      "X-Axios": "alfred1",
      "X-Alfred-Version": "alfred@v1.18.172+52112"
    },
    "delete": {},
    "get": {},
    "head": {},
    "post": {},
    "put": {},
    "patch": {}
  };
  var axiosOptions = {
    baseURL,
    headers
  };

  // Proxy SSR request headers headers
  if (process.server && ctx.req && ctx.req.headers) {
    var reqHeaders = _objectSpread({}, ctx.req.headers);
    for (var h of ["accept", "cf-connecting-ip", "cf-ray", "content-length", "content-md5", "content-type", "host", "x-forwarded-host", "x-forwarded-port", "x-forwarded-proto"]) {
      delete reqHeaders[h];
    }
    axiosOptions.headers.common = _objectSpread(_objectSpread({}, reqHeaders), axiosOptions.headers.common);
  }
  if (process.server) {
    // Don't accept brotli encoding because Node can't parse it
    axiosOptions.headers.common['accept-encoding'] = 'gzip, deflate';
  }
  var axios = createAxiosInstance(axiosOptions);

  // Inject axios to the context as $axios
  ctx.$axios = axios;
  inject('axios', axios);
});